import styled, { device } from "@styled";

export const LocTechIntroWrapper = styled.section`
  padding: 80px 0;
`;
export const LocTechIntroInner = styled.div``;

export const LocTechIntrowrap = styled.div`
  margin: 15px 0;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 28px;
  ${device.medium} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${device.xlarge} {
    grid-template-columns: repeat(3, 1fr);
  }
`;
export const LocTechIntrowrapInner = styled.div`
  border: 1px solid #aaaaaa;
  border-radius: 6px;
  padding: 0 15px 100px;
  min-height: 360px;
  position: relative;
  ul {
    padding: 0;
    display: grid;
    grid-template-rows: repeat(5, 1fr);
    grid-auto-flow: column;
    li {
      font-size: 15px !important;
      margin-bottom: 5px !important;
    }
  }
`;

export const LocTechIntrowrapHead = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0;
  margin-bottom: 20px;
  border-bottom: 1px solid #aaaaaa;
  .gatsby-image-wrapper {
    width: 42px;
    height: 42px;
    img {
      object-fit: contain !important;
    }
  }
`;
export const LocTechIconwrap = styled.div`
  min-width: 80px;
  width: 80px;
  height: 80px;
  background: #fff;
  border: 1px solid #541681;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;

  img {
    max-width: 35px;
  }
`;

export const LocTechIntrowrapFoot = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 15px;
  position: absolute;
  bottom: 0;
  left: 15px;
  right: 15px;
  .gatsby-image-wrapper {
    height: 42px;
    img {
      object-fit: contain !important;
    }
  }
`;
