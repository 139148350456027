import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import SectionTitle from "@ui/section-title";
import Heading from "@ui/heading";
import Image from "@ui/image";
import List, { ListItem } from "@ui/list";

import {
  LocTechIntroWrapper,
  LocTechIntroInner,
  LocTechIntrowrap,
  LocTechIntrowrapInner,
  LocTechIntrowrapHead,
  LocTechIconwrap,
  LocTechIntrowrapFoot,
} from "./style";

export default function LocTechIntro({ headingStyle, data }) {
  return (
    <LocTechIntroWrapper id={data.section}>
      <LocTechIntroInner>
        <Container>
          <Row>
            <Col lg={12}>
              <SectionTitle
                subtitle={data.section_title.subtitle}
                title={data.section_title.title}
                description={data.section_title.description}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <LocTechIntrowrap>
                {data &&
                  data.items.map((el, index) => (
                    <div key={index}>
                      <LocTechIntrowrapInner>
                        <LocTechIntrowrapHead>
                          <LocTechIconwrap>
                            <Image
                              src={el.images[0]?.src}
                              alt={el.images[0]?.alt || "Info"}
                            />
                          </LocTechIconwrap>
                          <Heading {...headingStyle}>{el.title}</Heading>
                        </LocTechIntrowrapHead>

                        <List $layout="circle">
                          {!!el.list &&
                            el.list.map((el, index) => <ListItem key= {index}>{el.text}</ListItem>)}
                        </List>

                        <LocTechIntrowrapFoot>
                          {el &&
                            el.images.map((data, index1) => (
                              <div key={index1}>
                                {index1 !== 0 ? (
                                  <div key={index1}>
                                    <Image
                                      src={data.src}
                                      alt={data.alt || "logo"}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            ))}
                        </LocTechIntrowrapFoot>
                      </LocTechIntrowrapInner>
                    </div>
                  ))}
              </LocTechIntrowrap>
            </Col>
          </Row>
        </Container>
      </LocTechIntroInner>
    </LocTechIntroWrapper>
  );
}

LocTechIntro.propTypes = {
  headingStyle: PropTypes.object,
};
LocTechIntro.defaultProps = {
  headingStyle: {
    as: "h6",
    fontSize: "22px",
    m: 0,
  },
};
